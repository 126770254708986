<template>
    <div :class="[{ open: openList }, customMainClass]">
        <input
            :id="id"
            ref="searchInput"
            v-model="searchText"
            :class="props.customClass"
            autocomplete="off"
            :placeholder="props.placeholder"
            type="text"
            :maxlength="props.maxLength"
            @keydown.esc="onBlur"
            @keydown.enter="onEnterKey($event)"
            @keydown.down="onArrowKey(1)"
            @keydown.up="onArrowKey(-1)"
            @input="onChange"
            @blur="onBlur"
            @focus="onFocus" />
        <!-- No results message -->
        <div
            v-if="props.showMinLengthMessage && !results.length"
            class="hidden dropdown-menu z-10"
            :class="props.customContainerClass">
            <div class="italic text-gray-500 p-2 text-sm">{{ $t(minLengthMessage) }}</div>
        </div>
        <!-- Grouped results (main search) -->
        <div
            v-else-if="groupedResults && groupedResults.length"
            class="hidden dropdown-menu z-10"
            :class="props.customContainerClass">
            <div class="autocomplete-groups">
                <ul v-for="group in groupedResults" :key="group.index" :class="props.customListClass">
                    <li class="!py-0.5">
                        <strong>{{ $t(group.text, [group.results.length]) }}</strong>
                    </li>
                    <li
                        v-for="result in group.results"
                        :key="result.category + '-' + result.label"
                        :class="[{ active: results.indexOf(result) === selectedIndex }, getCustomClass(result)]"
                        class="!py-0.5"
                        @mousedown="selectItem(result, $event)">
                        <a href="#" class="link-gray">{{ result.label }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- Standard results -->
        <ul v-else class="hidden dropdown-menu z-10" :class="props.customContainerClass">
            <li
                v-for="(result, i) in results"
                :key="result.label"
                :class="[{ active: i === selectedIndex }, getCustomClass(result)]"
                @mousedown="selectItem(result, $event)">
                <a href="#" class="link-gray">{{ result.label }}</a>
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
import type { AutocompleteSearchResponseItem } from "~/composables/search";
type AutocompleteResultGroup = {
    index: string;
    text: string;
    results: Array<AutocompleteSearchResponseItem>;
};

const props = defineProps({
    results: { type: Array<AutocompleteSearchResponseItem>, required: true },
    id: { type: String, default: null },
    placeholder: { type: String, default: null },
    showMinLengthMessage: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    selectOnFocus: { type: Boolean, default: false },
    minLengthMessage: {
        type: String,
        default: "Layout.SearchMinimumLengthMessage",
    },
    minSearchLength: {
        type: Number,
        default: MIN_SEARCH_LENGTH,
    },
    maxLength: {
        type: Number,
        default: 100,
    },
    customClass: {
        type: String,
        default: "",
    },
    customListClass: {
        type: String,
        default: "dropdown-menu",
    },
    customContainerClass: {
        type: String,
        default: "dropdown-menu",
    },
    customMainClass: {
        type: String,
        default: "autocomplete",
    },
    headers: { type: Object, default: null },
    clearAfterSelect: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["select", "clear", "enter", "blur", "search", "focus"]);
const isOpen = ref(false);
const selectedIndex = ref(-1);
const searchText = defineModel<string>({ required: true });
const searchInput = ref<HTMLInputElement | null>(null);

const openList = computed(() => {
    if (
        props.showMinLengthMessage &&
        searchText.value &&
        searchText.value.trim().length < props.minSearchLength &&
        isOpen.value === true
    ) {
        return true;
    }
    return props.results?.length != 0 && searchText.value.length != 0 && isOpen.value === true;
});

const groupedResults = computed(() => {
    if (!props.headers) {
        return null;
    }

    const categories = Object.keys(props.headers);
    if (!categories || !categories.length) {
        return null;
    }

    const groups: Array<AutocompleteResultGroup> = [];
    for (const category of categories) {
        groups.push({
            index: category,
            text: props.headers[category],
            results: [],
        });
    }

    for (const result of props.results) {
        if (result.category) {
            const group = groups.find((x) => x.index == result.category);
            if (group) {
                group.results.push(result);
            }
        }
    }

    return groups.filter((x) => x.results.length);
});

function selectItem(item: AutocompleteSearchResponseItem, e: Event) {
    // stop the selection event from bubbling to the blur event
    e.preventDefault();
    e.stopPropagation();

    if (props.clearAfterSelect) {
        searchText.value = "";
    } else {
        searchText.value = item.label;
    }

    isOpen.value = false;
    emit("select", item);
    emit("clear");
}

function onEnterKey(e: Event) {
    if (selectedIndex.value == -1) {
        isOpen.value = false;
        emit("clear");
        emit("enter", searchText.value);
    } else {
        const selection = props.results[selectedIndex.value];
        selectItem(selection, e);
    }
}

function onBlur() {
    isOpen.value = false;
    selectedIndex.value = -1;
    emit("blur", searchText.value);
}

function onArrowKey(increment: number) {
    if (increment == -1) {
        if (selectedIndex.value > 0) {
            selectedIndex.value--;
        }
    } else if (props.results && selectedIndex.value < props.results.length - 1) {
        selectedIndex.value++;
    }
}

function onChange() {
    if (isOpen.value == false) {
        isOpen.value = true;
        selectedIndex.value = -1;
    }

    if (!searchText.value || searchText.value.trim().length < props.minSearchLength) {
        emit("clear");
        return;
    }

    emit("search", searchText.value);
}

function onFocus() {
    emit("focus");
    if (props.selectOnFocus) {
        searchInput.value?.select();
    }
}

function getCustomClass(result: AutocompleteSearchResponseItem) {
    return result.class != null ? result.class : "";
}

onMounted(() => {
    if (props.autofocus) {
        searchInput.value?.focus();
    }
});
</script>
